body .rangeSlider {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

body .rangeSliderInner {
    height: 36px;
    display: flex;
    width: 100%;
}

body .rangeSliderBack {
    height: 36px;
    display: flex;
    width: 100%;
}

body .rangeSliderFront {
    height: 3px;
    width: 100%;
    border-radius: 1px;
    align-self: center;
}

body .rangeSliderThumb {
    height: 14px;
    width: 14px;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #bec2c3;
    position: absolute;
}

body .rangeSliderOutput {
    margin: 15px 0 3px;
    display: block;
    font-size: 0;
    line-height: 0;
    width: 100%;
}

body .rangeSliderInputBox {
    margin: 0 29px 0 0;
    display: inline-block;
    vertical-align: top;
    width: calc(50% - 14.5px);
    position: relative;
}

body .rangeSliderInputBox:first-child:after {
    content: '—';
    display: block;
    color: #222222;
    position: absolute;
    top: 50%;
    pointer-events: none;
    margin: -9.5px 0 0 6.5px;
    left: 100%;
    font: normal 400 14px/19px 'Neue Helvetica Paneuropean 55 Roman', sans-serif;
}

body .rangeSliderInputBox:last-child {
    margin-right: 0;
}

body .rangeSliderInput {
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    background: #fff;
    border: 1px solid #bec2c3;
    box-shadow: none;
    padding: 2px 9px 0;
    height: 36px;
    border-radius: 3px;
}
